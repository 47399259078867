import { render, staticRenderFns } from "./statisticalDetails.vue?vue&type=template&id=7fc7f642&scoped=true"
import script from "./statisticalDetails.vue?vue&type=script&lang=js"
export * from "./statisticalDetails.vue?vue&type=script&lang=js"
import style0 from "./statisticalDetails.vue?vue&type=style&index=0&id=7fc7f642&prod&lang=less&scoped=true"
import style1 from "./statisticalDetails.vue?vue&type=style&index=1&id=7fc7f642&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fc7f642",
  null
  
)

export default component.exports